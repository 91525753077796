const Types = {
  LOAD: '@triggers/LOAD',
  LOAD_SUCCESS: '@triggers/LOAD_SUCCESS',
  CHANGE_ACTIVATOR: '@triggers/CHANGE_ACTIVATOR',
  REMOVE_TRIGGER: '@triggers/REMOVE_TRIGGER',
  CHANGE_ACTION: '@triggers/CHANGE_ACTION',
  ADD_TRIGGER_WORD: '@triggers/ADD_TRIGGER_WORD',
  UPDATE_MESSAGE: '@triggers/UPDATE_MESSAGE',
  REMOVE_WORD: '@triggers/REMOVE_WORD',
  UPDATE_INACTIVITY_TIME: '@triggers/UPDATE_INACTIVITY_TIME',
  UPDATE_TIME_WITHOUT_RESPONSE: '@triggers/UPDATE_TIME_WITHOUT_RESPONSE',
  UPDATE_SQUARE: '@triggers/UPDATE_SQUARE',
  UPDATE_AVOID_MEDIA_SENDING: '@triggers/UPDATE_AVOID_MEDIA_SENDING',
  UPDATE_AVOID_AUDIO_SENDING: '@triggers/UPDATE_AVOID_AUDIO_SENDING',
  SAVE_BOT_TRIGGERS: '@triggers/SAVE_BOT_TRIGGERS',
  CREATE_EMPTY_TRIGGER: '@triggers/CREATE_EMPTY_TRIGGER',
};

export default Types;
