import types from './types';

export const loadTriggers = (botId) => ({
  type: types.LOAD,
  payload: { botId },
});

export const loadTriggersSucces = (payload) => ({
  type: types.LOAD_SUCCESS,
  payload,
});

export const changeActivator = (triggerId, activator) => ({
  type: types.CHANGE_ACTIVATOR,
  payload: { triggerId, activator },
});

export const deleteTrigger = (triggerId) => ({
  type: types.REMOVE_TRIGGER,
  payload: { triggerId },
});

export const changeAction = (triggerId, action) => ({
  type: types.CHANGE_ACTION,
  payload: { triggerId, action },
});

export const addTriggerWord = (triggerId, word) => ({
  type: types.ADD_TRIGGER_WORD,
  payload: { triggerId, word },
});

export const updateMessage = (triggerId, message) => ({
  type: types.UPDATE_MESSAGE,
  payload: { triggerId, message },
});

export const removeWord = (triggerId, wordArray) => ({
  type: types.REMOVE_WORD,
  payload: { triggerId, wordArray },
});

export const updateInactivityTime = (triggerId, time) => ({
  type: types.UPDATE_INACTIVITY_TIME,
  payload: { triggerId, time },
});

export const updateTimeWithoutResponse = (time) => ({
  type: types.UPDATE_TIME_WITHOUT_RESPONSE,
  payload: { time },
});

export const updateSquare = (triggerId, squareId) => ({
  type: types.UPDATE_SQUARE,
  payload: { triggerId, squareId },
});

export const updateAvoidMediaSending = (triggerId, value) => ({
  type: types.UPDATE_AVOID_MEDIA_SENDING,
  payload: { triggerId, value },
});

export const updateAvoidAudioSending = (triggerId, value) => ({
  type: types.UPDATE_AVOID_AUDIO_SENDING,
  payload: { triggerId, value },
});

export const saveBotTriggers = (botId, triggers, maxTimeWithoutResponse) => ({
  type: types.SAVE_BOT_TRIGGERS,
  payload: { botId, triggers, maxTimeWithoutResponse },
});

export const createEmptyTrigger = (objectId) => {
  return {
    type: types.CREATE_EMPTY_TRIGGER,
    payload: {
      receivedValues: [],
      version: 0,
      _id: objectId,
      inactivityTime: '',
      activator: 'time',
      action: 'message',
      message: '',
      square: '',
    },
  };
};
