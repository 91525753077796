import produce from 'immer';

import types from './types';

const INITIAL_STATE = {
  bots: [],
  botsCompany: [],
  bot: {},
  botCollectDataCount: [],
  botPhrases: {
    totalPages: 1,
    records: [],
    totalRecords: 0,
    page: 1,
  },
  variables: {
    bot: [],
    global: [],
    vtex: [],
  },
  attendanceQueues: [],
  openedModal: '',
  openedModalSuggestion: '',
  isLoadingPublish: false,
  isLoadingStageArea: false,
  sessions: [],
  sessionById: {},
  messages: [],
  isLoadingActive: false,
  previewIsOpen: false,
  builderTracking: [],
  builderTrackingDetails: {},
  botsWithShareableAI: [],
  builderPendings: [],
  forks: [],
  returnedBot: {
    botId: undefined,
    url: undefined,
    itemId: undefined,
    positionOnScreen: undefined,
  },
  behaviorPendings: [],
  publishStatus: {},
  usersPresenceBuilder: [],
};

export default function bots(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.FETCH_BOTS_REQUEST: {
        draft.bots = [];
        break;
      }

      case types.FETCH_BOTS_SUCCESS: {
        draft.bots = action.payload.bots;
        break;
      }

      case types.FETCH_BOTS_BY_COMPANY_REQUEST: {
        draft.botsCompany = [];
        break;
      }

      case types.FETCH_BOTS_BY_COMPANY_SUCCESS: {
        draft.botsCompany = action.payload.bots;
        break;
      }

      case types.FETCH_BOT_SUCCESS: {
        draft.bot = action.payload.bot;
        break;
      }

      case types.FETCH_COLLECT_DATA_COUNT_SUCCESS: {
        draft.botCollectDataCount = action.payload.data;
        break;
      }

      case types.CREATE_SUCCESS: {
        const { newBot } = action.payload;
        const newBotList = [...state.bots, newBot];
        draft.bots = newBotList;
        break;
      }

      case types.DELETE_SUCCESS: {
        const { id } = action.payload;
        const newBotList =
          state?.bots.length > 0
            ? state.bots.filter((bot) => bot._id !== id)
            : [];

        draft.bots = newBotList;
        break;
      }

      case types.CLONE_REQUEST_SUCCESS: {
        const newBot = action.payload;
        const newBotList = [...state.bots, newBot];
        draft.bots = newBotList;
        break;
      }

      case types.UPDATE_STATUS_REQUEST_SUCCESS: {
        const { isActive } = action.payload;
        const updatedBot = { ...state.bot, isActive };
        draft.bot = updatedBot;
        break;
      }

      case types.UPDATE_BOT_SUCCESS: {
        const { payload } = action;
        const { data } = payload;
        const ListWithoutNewBot =
          state?.bots.length > 0
            ? state.bots.filter((bot) => bot._id !== data?._id)
            : [];
        const newBotList = [...ListWithoutNewBot, data];
        draft.bots = newBotList;
        draft.bot = {
          ...draft.bot,
          ...data,
        };
        if (data.settings) {
          draft.bot.settings = data.settings;
        }
        break;
      }

      case types.UPDATE_LAST_UPDATE_SUCCESS: {
        const { payload } = action;

        const updatedBot = {
          ...state.bot,
          ...payload,
        };

        draft.bot = updatedBot;
        break;
      }

      case types.RETRIEVE_ATTENDANCE_HOUR_SETTINGS: {
        const { payload } = action;
        const { settings } = payload;
        draft.bot.settings = settings;
        break;
      }
      case types.GET_PHRASEOLOGY_SUCCESS: {
        const { records, totalRecords, page, totalPages } = action.payload;
        draft.botPhrases = {
          records,
          page,
          totalRecords,
          totalPages,
        };
        break;
      }

      case types.GET_VARIABLES_SUCCESS: {
        const { bot, global, vtex } = action.payload;
        draft.variables = {
          bot,
          global,
          vtex,
        };
        break;
      }

      case types.GET_BOT_HUMAN_ATTENDANCE_QUEUES_SUCCESS: {
        draft.attendanceQueues = action.payload.queues;
        break;
      }

      case types.PUBLISH_BOT_REQUEST: {
        draft.isLoadingPublish = true;
        break;
      }
      case types.PUBLISH_BOT_SUCCESS: {
        draft.isLoadingPublish = false;
        draft.openedModal = '';
        draft.bot = {
          ...action.payload.bot,
          isActive: true,
        };
        break;
      }
      case types.PUBLISH_BOT_FAILED: {
        draft.isLoadingPublish = false;
        break;
      }

      case types.CHANGE_STAGING_AREA_REQUEST: {
        draft.isLoadingStageArea = true;
        break;
      }
      case types.CHANGE_STAGING_AREA_SUCCESS: {
        draft.isLoadingStageArea = false;
        draft.openedModal = '';
        break;
      }
      case types.CHANGE_STAGING_AREA_FAILED: {
        draft.isLoadingStageArea = false;
        break;
      }

      case types.SET_OPENED_MODAL: {
        draft.openedModal = action.payload.name;
        break;
      }

      case types.SET_OPENED_MODAL_SUGGESTION: {
        draft.openedModalSuggestion = action.payload.name;
        break;
      }

      case types.GET_SESSIONS_SUCCESS: {
        draft.sessions = action.payload.sessions;
        break;
      }

      case types.GET_SESSION_BY_ID_SUCCESS: {
        draft.sessionById = action.payload.session;
        draft.customPhoneMask = action.payload?.customPhoneMask;
        break;
      }

      case types.GET_MESSAGES_SESSIONS_SUCCESS: {
        draft.messages = action.payload.messages;
        break;
      }

      case types.SET_OPENED_MODAL_BOT: {
        draft.openedModal = action.payload.name;
        break;
      }

      case types.ACTIVE_BOT_LOADING_START: {
        draft.isLoadingActive = true;
        break;
      }

      case types.ACTIVE_BOT_LOADING_FINISH: {
        draft.isLoadingActive = false;
        break;
      }

      case types.SET_PREVIEW_IS_OPEN: {
        draft.previewIsOpen = action.payload;
        break;
      }

      case types.UPDATE_QUICK_ACCESS_SUCCESS: {
        const updatedBot = { ...state.bot, quickAccess: action.payload };
        draft.bot = updatedBot;
        break;
      }

      case types.GET_HOLIDAYS_SUCESS: {
        const updatedBot = { ...state.bot, holidays: action.payload };
        draft.bot = updatedBot;
        break;
      }

      case types.FETCH_BUILDER_TRACKING_SUCCESS: {
        draft.builderTracking = action.payload;
        break;
      }

      case types.FETCH_BUILDER_TRACKING_DETAILS_SUCCESS: {
        draft.builderTrackingDetails = action.payload;
        break;
      }

      case types.CLONE_URA_SUCCESS: {
        const newBot = action.payload;
        const newBotList = [...state.bots, newBot];
        draft.bots = newBotList;
        break;
      }

      case types.SET_BUILDER_TRACKING_DETAILS: {
        draft.builderTrackingDetails = action.payload;
        break;
      }

      case types.FETCH_BOTS_WITH_SHAREABLE_IA_SUCCESS: {
        draft.botsWithShareableAI = action.payload;
        break;
      }

      case types.FETCH_BUILDER_PENDINGS_SUCCESS: {
        draft.builderPendings = action.payload;
        break;
      }

      case types.RETURN_BOT_SUCCESS: {
        draft.returnedBot = action.payload.returnedBot;
        break;
      }

      case types.FETCH_BEHAVIOR_PENDINGS_SUCCESS: {
        draft.behaviorPendings = action.payload;
        break;
      }

      case types.SET_MESSAGE_FAVORITE_SUCCESS: {
        const { messageId, userId } = action.payload;

        const updatedMessages = state.messages.map((message) => {
          if (message._id === messageId) {
            return {
              ...message,
              favorite: {
                isFavorite: !message?.favorite?.isFavorite,
                favoriteByUser: userId,
              },
            };
          }
          return message;
        });

        draft.messages = updatedMessages;
        break;
      }
      case types.SET_MESSAGE_COMMENT_SUCCESS: {
        const { userId, comment, messageId, commentId } = action.payload;

        const updatedMessages = state.messages.map((message) => {
          if (message._id === messageId) {
            return {
              ...message,
              comments: [
                ...message.comments,
                { userId, comment, _id: commentId, deleted: false },
              ],
            };
          }
          return message;
        });

        draft.messages = updatedMessages;
        break;
      }
      case types.REMOVE_MESSAGE_COMMENT_SUCCESS: {
        const { messageId, commentId } = action.payload;

        const updatedMessages = state.messages.map((message) => {
          if (message._id === messageId) {
            const updatedComments = message.comments.map((comment) => {
              if (comment._id === commentId) {
                return {
                  ...comment,
                  deleted: true,
                };
              }
              return comment;
            });
            return {
              ...message,
              comments: updatedComments,
            };
          }
          return message;
        });
        draft.messages = updatedMessages;
        break;
      }
      case types.SET_CUSTOM_CHANNEL_API_KEY_SUCCESS: {
        const response = {
          created: action.payload.created,
          viewed: action.payload.viewed,
          generatedAt: action.payload.generatedAt,
        };

        const customChannel = draft.bot?.channels?.find(
          (ch) => ch.channelId === 'custom'
        );

        if (customChannel) {
          draft.bot.channels = draft.bot.channels.map((channel) => {
            if (channel.channelId === 'custom') {
              return {
                ...channel,
                settings: {
                  ...channel.settings,
                  apiKey: {
                    created: response.created,
                    value: '',
                    viewed: response.viewed,
                    generatedAt: response.generatedAt,
                  },
                },
              };
            }
            return channel;
          });
        } else {
          draft.bot.channels = [
            ...draft.bot.channels,
            {
              channelId: 'custom',
              settings: {
                apiKey: {
                  created: response.created,
                  value: '',
                  viewed: response.viewed,
                  generatedAt: response.generatedAt,
                },
              },
            },
          ];
        }
        break;
      }
      case types.VIEW_CUSTOM_CHANNEL_API_KEY_SUCCESS: {
        const apiKey = action.payload;
        draft.bot.channels = draft.bot.channels.map((channel) => {
          if (channel.channelId === 'custom') {
            return {
              ...channel,
              settings: {
                ...channel.settings,
                apiKey,
              },
            };
          }
          return channel;
        });
        break;
      }
      case types.DELETE_CUSTOM_CHANNEL_API_KEY_SUCCESS: {
        draft.bot.channels = draft.bot.channels.map((channel) => {
          if (channel.channelId === 'custom') {
            return {
              ...channel,
              settings: {
                ...channel.settings,
                apiKey: {
                  created: false,
                  value: '',
                  viewed: false,
                  generatedAt: 0,
                },
              },
            };
          }
          return channel;
        });
        break;
      }

      case types.UPDATE_CHANNEL_REQUEST:
        {
          const { payload } = action;
          const isSameChannel = (channelId) => {
            return (
              channelId?.toLowerCase?.() === payload.channelId?.toLowerCase()
            );
          };
          draft.bot.channels = draft.bot.channels?.map((channel) => {
            if (isSameChannel(channel.channelId)) {
              return payload.data;
            }
            return channel;
          });
          const existingChannel = draft.bot.channels?.find((channel) =>
            isSameChannel(channel.channelId)
          );
          if (!existingChannel) {
            draft.bot.channels.push(payload.data);
          }
        }
        break;

      case types.DELETE_CHANNEL_REQUEST: {
        draft.bot.channels = draft.bot.channels.filter(
          (channel) => channel.channelId !== action.payload.channelId
        );
        break;
      }

      case types.CREATE_FORK_REQUEST_SUCCESS: {
        draft.bot.forks = [...draft.bot.forks, action.payload];
        break;
      }

      case types.DELETE_FORK_REQUEST_SUCCESS: {
        draft.bot.forks = draft.bot.forks.filter(
          (fork) => fork.botId !== action.payload.forkId
        );
        break;
      }

      case types.UPDATE_STATUS_FORK_SUCCESS: {
        if (action?.payload?.forkId && action?.payload?.status) {
          const forkIndex = draft.bot.forks?.findIndex(
            (fork) => fork?._id === action?.payload?.forkId
          );
          if (forkIndex >= 0) {
            draft.bot.forks[forkIndex].status = action?.payload?.status;
            draft.bot.forks[forkIndex].updatedAt = action?.payload.updatedAt;
          }
        }
        break;
      }

      case types.SET_PUBLISH_STATUS: {
        draft.publishStatus[action.payload.botId] =
          action.payload.publishStatus;
        break;
      }

      case types.FETCH_USERS_PRESENCE_BUILDER_SUCCESS: {
        draft.usersPresenceBuilder = action.payload;
        break;
      }

      default:
        break;
    }
  });
}
