import produce from 'immer';
import types from './types';

const INITIAL_STATE = {
  triggers: [],
  maxTimeWithoutResponse: '',
};

export default function triggers(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.LOAD: {
        draft = state;
        break;
      }

      case types.LOAD_SUCCESS: {
        draft.triggers = action.payload.triggers;
        draft.maxTimeWithoutResponse = action.payload.maxTimeWithoutResponse;
        break;
      }

      case types.CHANGE_ACTIVATOR: {
        const triggerActivatorIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[triggerActivatorIndex].activator =
          action.payload.activator;
        break;
      }

      case types.triggerSquare: {
        const findIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft = state.triggers.splice(findIndex, 1);
        break;
      }

      case types.CHANGE_ACTION: {
        const findActionTrigger = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findActionTrigger].action = action.payload.action;

        break;
      }

      case types.REMOVE_TRIGGER: {
        const findTrigger = state.triggers.filter(
          (trigger) => trigger._id !== action.payload.triggerId
        );

        draft.triggers = findTrigger;
        break;
      }

      case types.UPDATE_MESSAGE: {
        const findMessageTrigger = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findMessageTrigger].message = action.payload.message;
        break;
      }

      case types.ADD_TRIGGER_WORD: {
        const findTriggerWordIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findTriggerWordIndex].receivedValues = [
          ...state.triggers[findTriggerWordIndex].receivedValues,
          action.payload.word,
        ];

        break;
      }

      case types.REMOVE_WORD: {
        const findRemoveWordIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findRemoveWordIndex].receivedValues =
          action.payload.wordArray;

        break;
      }

      case types.UPDATE_INACTIVITY_TIME: {
        const findInactivityTimeIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findInactivityTimeIndex].inactivityTime =
          action.payload.time;
        break;
      }

      case types.UPDATE_TIME_WITHOUT_RESPONSE: {
        draft.maxTimeWithoutResponse = action.payload.time;

        break;
      }

      case types.UPDATE_SQUARE: {
        const findIndexToUpdateSquare = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );

        draft.triggers[findIndexToUpdateSquare].square =
          action.payload.squareId;

        break;
      }

      case types.UPDATE_AVOID_MEDIA_SENDING: {
        const triggerIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );
        draft.triggers[triggerIndex].avoidMediaSending = action.payload.value;
        break;
      }

      case types.UPDATE_AVOID_AUDIO_SENDING: {
        const triggerIndex = state.triggers.findIndex(
          (trigger) => trigger._id === action.payload.triggerId
        );
        draft.triggers[triggerIndex].avoidAudioSending = action.payload.value;
        break;
      }

      case types.CREATE_EMPTY_TRIGGER: {
        const baseState = {
          receivedValues: action.payload.receivedValues,
          version: action.payload.version,
          _id: action.payload._id,
          inactivityTime: action.payload.inactivityTime,
          activator: action.payload.activator,
          action: action.payload.action,
          message: action.payload.message,
          square: action.payload.square,
        };
        draft.triggers = [...state.triggers, baseState];
        break;
      }

      default:
    }
  });
}
